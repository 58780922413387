import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "register"
};
const _hoisted_2 = {
  class: "register-title"
};
const _hoisted_3 = {
  class: "tabAddr acea-row row-left"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = {
  class: "register-label"
};
const _hoisted_7 = {
  key: 1,
  class: "addForm"
};
const _hoisted_8 = {
  class: "register-label"
};
const _hoisted_9 = {
  class: "toConts acea-row row-left"
};
const _hoisted_10 = {
  class: "register-label"
};
const _hoisted_11 = {
  class: "register-label"
};
const _hoisted_12 = {
  class: "register-label"
};
const _hoisted_13 = {
  class: "login-foot__text acea-row row-center-wrapper"
};
const _hoisted_14 = {
  class: "showActibe"
};
const _hoisted_15 = {
  class: "title"
};
const _hoisted_16 = {
  class: "search"
};
const _hoisted_17 = {
  class: "content"
};
const _hoisted_18 = ["onClick"];
import { useIntervalFn } from '@vueuse/core';
import { register, sendEmailCode } from '@/http';
import { router } from '@/router';
import { ref, computed } from 'vue';
import { showToast } from 'vant';
import { useI18n } from 'vue-i18n';
import country from '@/utils/country.json';
import { useUserStore } from '@/stores/user';
export default {
  __name: 'register',
  emits: ['returnLogin'],
  setup(__props, {
    emit: __emit
  }) {
    const CounTry = ref(country);
    const emit = __emit;
    const {
      t
    } = useI18n();
    const formData = ref({
      email: '',
      invite_code: '',
      password: '',
      pay_password: '',
      code: ''
    });
    const loading = ref(false);
    const returnLogin = () => {
      emit('returnLogin', false);
    };
    const back = () => {
      router.go(-1);
    };
    const onSubmit = () => {
      loading.value = true;
      let data = {};
      if (activeTab.value === 0) {
        data = {
          ...formData.value,
          email: formData.value.email
        };
        delete data.phone;
      } else {
        data = {
          ...formData.value,
          phone: valueCode.value + formData.value.email
        };
        delete data.email;
      }
      register(data).then(({
        code,
        msg
      }) => {
        loading.value = false;
        showToast(msg);
        if (code === 200) {
          router.push('/login');
        }
      }).catch(() => {
        loading.value = false;
      });
    };
    const _sendEmailCode = () => {
      if (isActive.value) return;
      if (!formData.value.email) {
        return showToast(t('please_enter_email'));
      }
      sendEmailCode({
        email: formData.value.email
      }).then(({
        code,
        msg
      }) => {
        showToast(msg);
        if (code === 200) {
          resume();
        }
      });
    };
    const count = ref(60);
    const {
      resume,
      pause,
      isActive
    } = useIntervalFn(() => {
      if (count.value === 0) {
        count.value = 60;
        pause();
      } else {
        count.value--;
      }
    }, 1000, {
      immediate: false
    });
    const userStore = useUserStore();
    const show = ref(false);
    const tabList = ref([t('email'), t('Phone')]);
    const activeTab = ref(0);
    const changeTab = index => {
      activeTab.value = index;
    };
    const value = ref();
    const valueCode = ref(66);
    const changeCode = item => {
      valueCode.value = item.country_code;
      show.value = false;
    };
    const pCodeKey = computed(() => {
      return {
        'en_US': 'country_name_en',
        'fr_FR': 'country_name_en',
        'es_ES': 'country_name_en',
        'it_IT': 'country_name_en',
        'en_TH': 'country_name_ta'
      }[userStore.lang];
    });
    // 不区分大小写的包含检查函数
    const includesIgnoreCase = (str, search) => {
      const regex = new RegExp(search, 'i');
      return str.match(regex);
    };
    const search = e => {
      let data = [];
      data = country.filter(v => includesIgnoreCase(v[pCodeKey.value], e));
      if (data.length <= 0) {
        data = country.filter(v => includesIgnoreCase(String(v.country_code), e));
        if (data.length <= 0) {
          data = country.filter(v => includesIgnoreCase(v.ab, e));
        }
      }
      CounTry.value = data;
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_el_link = _resolveComponent("el-link");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_search = _resolveComponent("van-search");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('create_account')), 1), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass({
            'active': index === activeTab.value
          }),
          onClick: $event => changeTab(index)
        }, _toDisplayString(item), 11, _hoisted_4);
      }), 256))]), activeTab.value === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('email')), 1), _createVNode(_component_van_field, {
        class: "register-input",
        modelValue: formData.value.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formData.value.email = $event),
        placeholder: _ctx.$t('input_email_account'),
        border: false
      }, null, 8, ["modelValue", "placeholder"])])) : (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('Phone')), 1), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", {
        class: "code acea-row row-center-wrapper",
        onClick: _cache[1] || (_cache[1] = $event => show.value = true)
      }, [_createElementVNode("div", null, "+" + _toDisplayString(valueCode.value), 1), _createVNode(_component_van_icon, {
        name: "arrow-down",
        color: "#fff"
      })]), _createVNode(_component_van_field, {
        class: "input register-input",
        modelValue: formData.value.email,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formData.value.email = $event),
        placeholder: _ctx.$t('input_phone_account'),
        border: false
      }, null, 8, ["modelValue", "placeholder"])])])), _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('verification_code')), 1), _createVNode(_component_van_field, {
        border: false,
        class: "register-input",
        placeholder: _ctx.$t('enter_verification_code'),
        modelValue: formData.value.code,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formData.value.code = $event)
      }, {
        button: _withCtx(() => [_createElementVNode("div", {
          style: {
            "color": "#56F9AA"
          },
          onClick: _sendEmailCode
        }, _toDisplayString(_unref(isActive) ? count.value + 's' : _ctx.$t('get_verification_code')), 1)]),
        _: 1
      }, 8, ["placeholder", "modelValue"]), _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('login_password')), 1), _createVNode(_component_van_field, {
        border: false,
        class: "register-input",
        type: "password",
        placeholder: _ctx.$t('set_login_password_input_8_plus_characters'),
        modelValue: formData.value.password,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formData.value.password = $event)
      }, null, 8, ["placeholder", "modelValue"]), _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('funds_password')), 1), _createVNode(_component_van_field, {
        border: false,
        class: "register-input",
        maxlength: 6,
        type: "password",
        placeholder: _ctx.$t('set_funds_password_6_digit_number'),
        modelValue: formData.value.pay_password,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formData.value.pay_password = $event)
      }, null, 8, ["placeholder", "modelValue"]), _createVNode(_component_van_field, {
        border: false,
        class: "register-input",
        placeholder: _ctx.$t('invitation_code'),
        modelValue: formData.value.invite_code,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => formData.value.invite_code = $event)
      }, null, 8, ["placeholder", "modelValue"]), _createElementVNode("div", _hoisted_13, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('createAcount.have')) + "？", 1), _createVNode(_component_el_link, {
        type: "primary",
        onClick: returnLogin
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('createAcount.goLogin')), 1)]),
        _: 1
      })]), _createVNode(_component_van_button, {
        color: "#56F9AA",
        style: {
          "color": "#333"
        },
        round: "",
        block: "",
        onClick: _cache[7] || (_cache[7] = $event => onSubmit()),
        loading: loading.value
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('register')), 1)]),
        _: 1
      }, 8, ["loading"])]), _createVNode(_component_van_popup, {
        show: show.value,
        "onUpdate:show": _cache[9] || (_cache[9] = $event => show.value = $event),
        position: "bottom",
        round: "",
        onClose: _cache[10] || (_cache[10] = $event => show.value = false),
        onOpen: _cache[11] || (_cache[11] = $event => show.value = true)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('选择国家或地区')), 1), _createElementVNode("div", _hoisted_16, [_createVNode(_component_van_search, {
          modelValue: value.value,
          "onUpdate:modelValue": [_cache[8] || (_cache[8] = $event => value.value = $event), search],
          shape: "round",
          "left-icon": "",
          "right-icon": "search",
          placeholder: _ctx.$t('请输入搜索关键词')
        }, null, 8, ["modelValue", "placeholder"])]), _createElementVNode("div", _hoisted_17, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(CounTry.value, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: "item acea-row row-between-wrapper",
            onClick: $event => changeCode(item)
          }, [_createElementVNode("div", null, _toDisplayString(item.country_name_en), 1), _createElementVNode("div", null, _toDisplayString(item.country_code), 1)], 8, _hoisted_18);
        }), 256))])])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};